<template>
  <div id='world' :style='{opacity, display}'>
    <div ref='container' :width='width' :height='height'></div>
    <canvas class='hidden' ref='arrowCanvas'
      :width='2 * arrowWidth' :height='2 * arrowHeight'
      :style='{width: `${arrowWidth}px`, height: `${arrowHeight}px`}'></canvas>
  </div>
</template>

<script>
import _ from 'lodash'
import rafLoop from 'raf-loop'
import * as d3 from 'd3'
import p5 from 'p5'
import * as THREE from 'three'
import fogImage from '../assets/fog-flipped.png'

// shaders
import wireframeVertexShader from '../assets/wireframe.vert'
import wireframeFragmentShader from '../assets/wireframe.frag'
import fillVertexShader from '../assets/fill.vert'
import fillFragmentShader from '../assets/fill.frag'

const zPositionOffset = 10
const orbitDistance = 20
const maxWidth = 6
const colors = {
  blue: 0x00c9ff,
  yellow: 0xf7e883,
  purple: 0x565A89,
}

export default {
  name: 'world',
  props: [
    'mountains', 'stars', 'tl1', 'tl2', 'tl3',
    'width', 'height', 'outerRadius', 'isMobile',
  ],
  data() {
    return {
      opacity: 1,
      display: 'block',
      cameraLookAtAngle: Math.PI / 2,
      cameraZPosition: -this.outerRadius - 2 * zPositionOffset,
      arrowX: 0,
      arrowY: 0,
      arrowZ: 0,
      arrowWidth: 100,
      arrowHeight: 160,
      featured: {}
    }
  },
  created() {
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(45, this.width / this.height, 1, 1000)
    this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})

    // WebGL background color
    this.renderer.setClearColor(0xffffff, 0)

    // set renderer size & pixel ratio
    this.renderer.setSize(this.width, this.height)
    const dpr = Math.min(2, window.devicePixelRatio)
    this.renderer.setPixelRatio(dpr)

    // set camera position
    this.moveCameraVec = new THREE.Vector3(0, 0, 1)
    this.camera.lookAt( 0, 0, -2 * this.outerRadius)

    // texture map, adapted from
    // https://gist.github.com/mattdesl/d74525cf21a9755383651289c799ac56
    this.renderer.domElement.style.visibility = 'hidden'
    this.fogMap = new THREE.TextureLoader().load(fogImage, texture => {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping
      this.renderer.domElement.style.visibility = 'visible'
      this.draw()
      this.loop.start()
    })
  },
  mounted() {
    this.$refs.container.appendChild(this.renderer.domElement)
    if (!this.isMobile) {
      this.drag = d3.drag().on('drag', this.panCamera)
      d3.select(this.$refs.container).call(this.drag)

      d3.select(this.$refs.container).on('mousemove', this.moveCamera)
    }

    // Setup our scene meshes
    this.createMeshes()
    this.calculateTimelines()

    // Initiate the animation loop, call draw on every "tick"
    this.loop = rafLoop(this.draw)
    this.clock = new THREE.Clock()
  },
  destroyed() {
    this.loop.stop().removeAllListeners()
    this.renderer.dispose()
  },
  watch: {
    width() {
      this.handleWindowResize()
    },
    height() {
      this.handleWindowResize()
    },
    arrowX() {
      const {arrowX, arrowY, arrowZ} = this.$data
      this.arrow.position.set(arrowX, arrowY, arrowZ)
    },
    cameraZPosition() {
      // update camera position
      this.camera.position.set( 0, 10, this.cameraZPosition )
      this.camera.position.addScaledVector(this.moveCameraVec, orbitDistance)
      this.camera.updateMatrixWorld()

      // and the corresponding mountain opacities
      _.each(this.mountains, ({z, meshes}) => {
        const opacity = this.calculateMountainOpacity(z)
        _.each(meshes, ({material}) => {
          material.uniforms.opacity.value = opacity
          material.visible = opacity > 1e-5
        })
      })
    },
    cameraLookAtAngle() {
      const r = -2 * this.outerRadius
      const x = r * Math.cos(this.cameraLookAtAngle)
      const z = r * Math.sin(this.cameraLookAtAngle)
      this.camera.lookAt( x, 0, z )
    },
  },
  methods: {
    draw: function () {
      const time = this.clock.getElapsedTime()
      _.each(this.mountains, ({meshes}) => {
        _.each(meshes, ({material}) => material.uniforms.time.value = 0.05 * time)
      })
      _.each(this.stars, (d, i) => {
        const noise = p5.prototype.noise(0.5 * time + i * 300)
        d.mesh.position.x = d.x + Math.sin(0.25 * time + i * 300)
        d.mesh.position.y = d.y + 2 * Math.cos(noise)
      })

      this.arrow.position.y = this.arrowY + 0.25 * Math.sin(3 * time)

      this.renderer.render(this.scene, this.camera)
    },
    createMeshes: function() {
      // mountains
      _.each(this.mountains, (d, i) => {
        const {x, z, width, height, bumps, colors} = d
        // trying to get a number from 0 to 1 inclusive
        let offset = _.random(this.mountains.length)
        // then convert it to -1...1 so that the noise
        // would be pushed out in both positive and negative directions
        offset = (offset / this.mountains.length) * 2 - 1;
        const {meshes, group} = this.createMountainMesh(z, width, height, offset, bumps, colors)
        group.position.set(x - width / 2, 0, z - height / 2)
        this.scene.add(group)
        // and then add the mesh to mountain object
        d.meshes = meshes
      })

      // stars
      const starGeometry = new THREE.SphereGeometry(0.1, 20, 20)
      _.each(this.stars, (d, i) => {
        const {x, y, z, size, color} = d
        const starMaterial = new THREE.MeshBasicMaterial( {
          color,
          side: THREE.DoubleSide,
        })
        const mesh = new THREE.Mesh(starGeometry, starMaterial)
        mesh.position.set(x, y, z)
        mesh.scale.set(size, size, size)
        this.scene.add( mesh )
        d.mesh = mesh
      })

      // a arrow
      this.arrow = this.createArrow()
      this.arrow.scale.set(0.75, -1, 1)
      this.scene.add(this.arrow)
    },
    createMountainMesh: function(z, width, height, offset, numPoints, colors) {
      const depth = height * 0.75
      const scale = width / maxWidth
      const aspect = width / height

      // Best to decouple the width (world units) from the
      // Actual # of subdivisions in the geometry. But
      // here we maintain the aspect ratio.
      const subdivX = width * 1
      const subdivY = height * 2
      const geometry = new THREE.PlaneGeometry(width, height, subdivX, subdivY)

      let y = 0
      const points = _.chain(numPoints)
        .times(i => {
          let x = 0
          let y = 0
          if (i === 0) return {x, y}
          if (i === numPoints - 1) return {x: 1, y}

          return {
            x: p5.prototype.randomGaussian(i / numPoints * 0.5 + 0.25, 0.01),
            y: p5.prototype.randomGaussian(i % 2 ? 1 : _.random(0.5, 0.8), 0.1),
          }
        }).value()

      const pointScale = d3.scaleLinear()
      let prev = 0
      let prevZ = 0
      let xoff = 0
      _.each(geometry.vertices, (v, i) => {
        // convert to 0...1 range
        let x = v.x / width + 0.5
        x = Math.max(Math.min(x, 1), 0)
        let z = 0.5 - v.y / height

        if (prevZ < z) {
          prevZ = z
          prev = 0
        }
        if (x < 1 && points[prev].x <= x) {
          pointScale.domain([points[prev].x, points[prev + 1].x])
            .range([points[prev].y, points[prev + 1].y])
          prev += 1
        }

        let y = 2 * (z <= 0.5 ? z : 1 - z)
        // let y = 4 * Math.pow(z <= 0.5 ? z : z - 1, 2)
        let horizontal = pointScale(x)
        // horizontal = Math.min(horizontal, 4 * Math.pow(x <= 0.5 ? x : x - 1, 2))
        // horizontal = Math.min(horizontal, Math.sin(x * Math.PI))
        y = Math.min(y, horizontal)
        // y = Math.max(y, 0)

        // taper the ends
        if (z > 0.5) {
          z = Math.min(Math.sin(x * Math.PI), z - 0.5)
        } else {
          z = Math.max(Math.sin((1 + x) * Math.PI), z - 0.5)
        }

        let noise = (p5.prototype.noise(xoff + i, 1) - 0.5)
        // minimize the variance as it gets close to the ends
        noise *= Math.sin(x * Math.PI)
        // x += 0.5 * offset
        y += 0.2 * noise
        z += 0.1 * noise

        v.x = width * x
        v.y = height * y
        v.z = depth * z

        xoff += 0.01
      })

      geometry.computeVertexNormals()

      const opacity = this.calculateMountainOpacity(z)
      const materials = [
        this.createFilledMaterial(opacity, offset, colors),
        this.createWireframeMaterial(opacity, 10, offset, colors), // opacity, frequency
      ]
      const group = new THREE.Group()
      const meshes = _.map(materials, material => {
        const mesh = new THREE.Mesh(geometry, material)
        group.add(mesh)
        return mesh
      })
      return {meshes, group}
    },
    createFilledMaterial: function(opacity, offset, colors) {
      return new THREE.ShaderMaterial({
        uniforms: {
          color1: { value: new THREE.Color( colors[0] ) },
          color2: { value: new THREE.Color( colors[1] ) },
          yellow: { value: new THREE.Color(colors[2]) },
          opacity: { value: opacity },
          offset: {value: offset},
          time: {value: 0},
          textureMap: {value: this.fogMap},
        },
        fragmentShader: fillFragmentShader,
        vertexShader: fillVertexShader,
      })
    },
    createWireframeMaterial: function(opacity, frequency, offset, colors) {
      return new THREE.ShaderMaterial({
        uniforms: {
          color: { value: new THREE.Color(colors[2]) },
          opacity: { value: opacity },
          frequency: {value: frequency},
          offset: {value: offset},
          time: {value: 0},
          textureMap: {value: this.fogMap},
        },
        transparent: true,
        fragmentShader: wireframeFragmentShader,
        vertexShader: wireframeVertexShader,
        wireframe: true,
      })
    },
    createArrow: function() {
      const canvas = this.$refs.arrowCanvas
      const ctx = canvas.getContext('2d')
      ctx.scale(2, 2)

      const x = this.arrowWidth / 2
      const y = this.arrowHeight / 2

      ctx.font = `${1.5 * this.arrowWidth}px sans-serif`
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText('↓', x, y)

      const texture = new THREE.Texture(canvas)
      const geometry = new THREE.PlaneGeometry(this.arrowWidth / 200, this.arrowHeight / 200, 1, 1)
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        opacity: 1.0,
        side: THREE.DoubleSide,
      })
      material.map.needsUpdate = true
      return new THREE.Mesh(geometry, material)
    },
    calculateTimelines: function() {
      this.tl3.fromTo(this.$data, 0.25, {opacity: 1, cameraZPosition: zPositionOffset},
        {opacity: 0, cameraZPosition: zPositionOffset * 6, ease:Linear.easeNone}, 0)
      this.tl3.set(this.$data, {display: 'none'}, 0.25)

      // animate camera for scroll
      // (second & third timelines comes first so that its initial camera position
      // doesn't overwrite the first timeline's initial position)
      // start a little after the furthest mountains and
      // end a little before the first mountain
      this.tl2.fromTo(this.$data, 1, {cameraZPosition: -this.outerRadius + 3 * zPositionOffset},
        {cameraZPosition: zPositionOffset, ease:Linear.easeNone}, 0)
      // start a little bit behind the furthest mountains
      // and go until a little before them
      this.tl1.fromTo(this.$data, 1, {cameraZPosition: -this.outerRadius - 4 * zPositionOffset},
        {cameraZPosition: -this.outerRadius + 3 * zPositionOffset, ease:Linear.easeNone}, 0)

      // go through each mountain and animate the sign thing
      let start = 0
      const zScale = d3.scaleLinear().domain([-this.outerRadius, 0])
      _.chain(this.mountains)
        .sortBy(d => d.z)
        .each((d, i) => {
          const end = zScale(d.z)
          this.tl2.to(this.$data, end - start, {
            arrowX: d.x,
            arrowY: d.height + 0.25,
            arrowZ: d.z - (d.height * 0.75) / 2,
          }, start)
          start = end
        }).value()
    },
    calculateMountainOpacity: function(z) {
      const dist = Math.abs(z - this.cameraZPosition)
      const opacity = Math.max(1 - dist / (this.outerRadius), 0)
      return Math.pow(opacity, 3)
    },
    moveCamera: function() {
      const {clientX, clientY} = d3.event
      const x = (clientX / window.innerWidth) * 2 - 1
      const y = (clientY / window.innerWidth) * 2 - 1

      // taken from https://gist.github.com/mattdesl/3ee1758a9052ee725c659d29bdcb1779

      const angle = 5 // degrees
      const orbit0 = x * angle
      const orbit1 = y * angle

      // A unit normal that goes toward the user
      this.moveCameraVec = new THREE.Vector3(0, 0, 1)
      // Rotate the unit normal
      this.moveCameraVec.applyAxisAngle(new THREE.Vector3(0, 1, 0), orbit0 * Math.PI / 180)
      this.moveCameraVec.applyAxisAngle(new THREE.Vector3(1, 0, 0), orbit1 * Math.PI / 180)

      // Make sure its still normalized
      this.moveCameraVec.normalize()

      // Reset camera
      this.camera.position.set( 0, 10, this.cameraZPosition )
      // Offset the camera by the unitNormal * orbitDistance
      this.camera.position.addScaledVector(this.moveCameraVec, orbitDistance)
      this.camera.updateMatrixWorld()
    },
    panCamera: function() {
      const {dx} = d3.event
      const angle = this.cameraLookAtAngle - dx / 1000

      if (0.4 * Math.PI < angle && angle < 0.6 * Math.PI) {
        this.cameraLookAtAngle = angle
      }
    },
    handleWindowResize: function() {
      this.renderer.setSize(this.width, this.height)
      this.camera.aspect = this.width / this.height
      this.camera.updateProjectionMatrix()
      this.draw()
    },
  }
}
</script>

<style scoped>
#world {
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  background: linear-gradient(180deg, rgba(255,248,248,1) 0%, rgba(255,255,251,1) 5%, rgba(255,255,255,1) 10%);
}

.hidden {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
</style>
