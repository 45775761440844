<template>
  <div id="intro" :style='{display}'>

    <div class='title subsection' :style='{opacity: titleOpacity}'>
      <h1 v-if='inEnglish'>
        hong kong
        <div style='color: #cfcfcf'>
          filmmakers, painters,<br />
          architects, musicians,<br />
          screenwriters, sculptors,<br />
          cartoonists, actresses,<br />
          fashion designers,<br />
          photographers, poets,
        </div>
        artists, women.
      </h1>
      <h1 v-if='!inEnglish'>
        香港
        <div style='color: #cfcfcf'>
          電影製作人、畫家、<br />
          建築師、音樂人、<br />
          編劇、雕塑家、<br />
          漫畫家、演員、<br />
          時裝設計師、<br />
          攝影師、詩人、
        </div>
        藝術家，女性。
      </h1>

      <p v-if='inEnglish'>
        A data visualisation by <a href='http://sxywu.com' target='_new'>Shirley Wu</a>
        <span v-if='!isMobile'> | </span>
        <span v-if='isMobile'><br /></span>
        Commissioned by <a href='https://stories.mplus.org.hk/en/interactives/' target='_new'>M+</a>, 2020.
      </p>
      <p v-if='!inEnglish'>
        由 <a href='http://sxywu.com' target='_new'>Shirley Wu</a> 創作的數據視覺化項目
        <span v-if='!isMobile'> | </span>
        <span v-if='isMobile'><br /></span>
        <a href='https://stories.mplus.org.hk/en/interactives/' target='_new'>M+</a> 委約作品，2020年。
      </p>
    </div>

    <div class='subsection' :style='{opacity: bodyOpacity}'>
      <div v-if='inEnglish'>
        <h2 v-if='!isMobile'>
          hong kong<br />artists, women.
        </h2>
        <p>Last December, I published <em>Legends</em>, a data visualisation highlighting the fifty-one female Nobel Laureates since 1901. In those same 117 years, there have been 853 male Laureates. These numbers are another shocking reminder of the structural underrepresentation we still face as women. I have since become determined to visualise more women-centred data sets.
        </p>
        <p>
        	<em>Hong Kong artists, women</em> is an extension of <em>Legends</em>, with a distinct call to action. It is based on a list of Hong Kong women artists with English articles on Wikipedia—entries that are user-generated. Developed in collaboration with M+, it is also a response to the museum’s ongoing effort to increase the representation of Asian women artists and makers online through initiatives such as
        	<a href="https://stories.mplus.org.hk/en/wikithon-results/" target="_new">Wikipedia Edit-a-thons</a>.
        </p>
        <p>Most importantly,
        	<em>Hong Kong artists, women</em> celebrates their accomplishments and addresses the pervasive underrepresentation of women. I invite you to fly through this landscape and explore. Linger on an artist, visit her Wikipedia page, consider and share her work.
        </p>
      </div>
      <div v-if='!inEnglish'>
        <h2 v-if='!isMobile'>
          香港<br />藝術家，女性。
        </h2>
        <p>去年12月，我發佈了《Legends》，這個數據視覺化作品聚焦於自1901年至今獲得諾貝爾獎的51位女性，而在這117年內，男性得主卻有853名。這些令人震驚的數字再次提醒我們，女性在今時今日的體制結構中仍備受忽略。自此，我決心以視覺化方式呈現更多以女性為中心的數據集。</p>
        <p>《香港藝術家，女性》是《Legends》的延伸，清晰地呼籲觀眾要行動起來。此作品以英文版維基百科上分類為「香港女性藝術家」的名單及當中條目內容為基礎，所有內容都是由維基百科用戶提供。我與M+合作建立這件作品，呼應館方一直致力藉「<a href="https://stories.mplus.org.hk/tc/wikithon-results/" target="_new">維基百科編輯馬拉松</a>」等活動，提高大眾在互聯網上對亞洲女性藝術家及創作者的關注。</p>
        <p>最重要的是，《香港藝術家，女性》彰顯這些女性的成就，並提出她們備受忽略之普遍狀況。我邀請各位在這片天地翱翔探索，隨心流連於藝術家的版面，瀏覽關於她的維基百科條目，從她的作品出發多作思考，繼而分享她的作品。</p>
      </div>
    </div>

    <div class='subsection' :style='{opacity: legendOpacity}'>
      <div v-if='inEnglish'>
        <h2 v-if='!isMobile'>
          how to read<br />this visualisation
        </h2>
        <p>
          I grew up with a set of Chinese ink paintings in my childhood home, each depicting the same mountain range in different seasons. I always found those mountains awe inspiring. I love the metaphor of these Hong Kong women artists as mountains—powerful, resilient, majestic, and rising above the mist.
        </p>
        <Legend v-bind='{path, colorScale, isMobile, inEnglish}'></Legend>
        <br />
        <p>
          The height of each mountain represents the word count of the corresponding artist’s Wikipedia page. The colour represents the number of other Wikipedia pages that mention her. The number of peaks represents the number of references at the bottom of the page. The mountains are surrounded by stars, each representing a male contemporary on Wikipedia.
        </p>
      </div>
      <div v-if='!inEnglish'>
        <h2 v-if='!isMobile'>
          如何閱讀<br />這個視覺化作品
        </h2>
        <p>
          我童年時，家中有一組水墨畫，每幅均描繪同一片山在四季遞嬗中的不同景致。我總對那些山巒感到驚豔歎絕，因此想以堅強、柔韌、巍峨，自迷霧中拔起的群山比喻這些香港女性藝術家。
        </p>
        <Legend v-bind='{path, colorScale, isMobile, inEnglish}'></Legend>
        <br />
        <p>
          每座山各代表一位女性藝術家的維基百科條目，山的高度對應條目的長度，顏色代表這位藝術家獲其他維基百科條目提及之次數多寡，山峰數目代表條目底部參考資料的數量。每座山都被群星環繞，每顆星代表一位擁有維基百科條目的同期男性藝術家。
        </p>
      </div>
    </div>

    <div class='subsection' :style='{opacity: dataOpacity}'>
      <div v-if='inEnglish'>
        <h2 v-if='!isMobile'>
          about this data set
        </h2>
        <p>The Wikipedia data set was pulled from the <a href="https://en.wikipedia.org/wiki/Category:Hong_Kong_women_artists" target="_new">‘Hong Kong women artists’</a> category, which is created through user-based tagging of Wikipedia’s existing articles. The resulting data set was small since many articles have not yet been tagged (or, indeed, created), but it remains Wikipedia’s only categorised list of Hong Kong women artists. The articles that do exist are often short, lacking basic information and citations.</p>
        <p>The shortcomings of this user-generated data set become clear as you explore the terrain of the visualisation; many of the mountains are not very tall—meaning that the articles are not so fleshed out—or are lightly coloured to show that there is little research supporting what does exist. Based, as they are, on individual Wikipedia users’ ad hoc additions to the site, these mountains are not a conclusive reflection of the artists’ individual achievements. </p>
      </div>
      <div v-if='!inEnglish'>
        <h2 v-if='!isMobile'>
          關於此數據集
        </h2>
        <p>這個維基百科數據集取自「<a href="https://en.wikipedia.org/wiki/Category:Hong_Kong_women_artists" target="_new">香港女性藝術家</a>」這個分類，是由用戶為已有條目標籤的。由於有很多條目尚待標籤，甚至還未建立，所以數據集只有有限的資料，不過這已是維基百科上唯一以香港女性藝術家分類的條目名單。這些現存的條目大多篇幅很短，缺乏基本資訊和引文。</p>
        <p>大家探索這片視覺化風景時，會漸漸發現這個由用戶生成的數據集的缺點：許多座山都不是很高，代表條目內容不大充實；或是山的顏色淺淡，顯示現存內容缺少研究資料佐證。由於山本身的數據由個別用戶自行添加至維基百科，這些山並不能全面反映藝術家的個人成就。</p>
        <p><strong>註：以下內容直接從維基百科英文條目中取得，故只能提供英文版本。</strong></p>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as d3 from 'd3'
import Legend from './Legend.vue'

export default {
  name: 'intro',
  components: {Legend},
  props: ['isMobile', 'inEnglish', 'tl', 'path', 'colorScale'],
  data() {
    return {
      display: 'block',
      titleOpacity: 0,
      bodyOpacity: 0,
      legendOpacity: 0,
      dataOpacity: 0,
    }
  },
  mounted() {
    const duration = 0.1
    const fades = [0.25, 0.5, 0.75, 1]
    let start = 0
    // fade out title
    start = fades[0] - duration
    this.tl.fromTo(this.$data, duration, {titleOpacity: 1}, {titleOpacity: 0}, start)
    // fade in body
    this.tl.to(this.$data, duration, {bodyOpacity: 1}, start)
    // fade out body
    start = fades[1] - duration
    this.tl.to(this.$data, duration, {bodyOpacity: 0}, start)
    // fade in legend
    this.tl.to(this.$data, duration, {legendOpacity: 1}, start)
    // fade out legend
    start = fades[2] - duration
    this.tl.to(this.$data, duration, {legendOpacity: 0}, start)
    // fade in data
    this.tl.to(this.$data, duration, {dataOpacity: 1}, start)
    // fade out data
    start = fades[3] - duration
    this.tl.to(this.$data, duration, {dataOpacity: 0}, start)
    this.tl.set(this.$data, {display: 'none'}, start + duration)
  }
}
</script>

<style scoped>
.subsection {
  position: absolute;
  top: 50vh;
  left: 50vw;
  width: 600px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.title {
  white-space: nowrap;
}

h1, h2 {
  margin: 0;
}

p {
  text-align: left;
}

a {
  pointer-events: auto;
}

blockquote p {
  font-family: 'Playfair Display';
  font-style: italic;
}

@media (min-width: 320px) and (max-width: 480px) {
  .subsection {
    top: 0px;
    left: 0px;
    width: calc(100% - 60px);
    padding: 40px 40px 20px 20px;
    transform: translate(0%, 0%);
  }
}
</style>
