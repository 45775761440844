<template>
  <div :class='`legend ${isMobile || !featured ? "grid" : ""}`'>
    <div class='column'>
      <p v-if='inEnglish'>
        <strong>article length</strong><br />
        <span v-if='featured'>{{ featured.data.content }} words</span>
      </p>
      <p v-if='!inEnglish'>
        <strong>文章篇幅</strong><br />
        <span v-if='featured'>{{ featured.data.content }}字</span>
      </p>
      <svg>
        <g transform='translate(0, 30)'>
          <!-- height -->
          <path v-for='i in 5' :d='path' :fill='heightFill(i)' :stroke-width='2 / 3'
            :stroke='featured ? featured.colors[1] : colorScale(0.5)'
            :transform='`scale(${(i)})translate(${i * 4}, 0)`'/>
        </g>
      </svg>
    </div>
    <div class='column'>
      <p v-if='inEnglish'>
        <strong>artist ‘influence’</strong><br />
        <span v-if='featured'>{{ featured.data.backlinks }} backlinks</span>
      </p>
      <p v-if='!inEnglish'>
        <strong>藝術家的「影響力」</strong><br />
        <span v-if='featured'>{{ featured.data.backlinks }}條反向連結</span>
      </p>
      <svg>
        <g transform='translate(0, 30)'>
          <!-- height -->
          <!-- <path v-for='i in 5' :d='path' :fill='heightFill(i)'
            :stroke='featured.colors[1]' :stroke-width='2 / (featured.height / 3)'
            :transform='`translate(${i * 30}, 0)scale(${featured.height / 3})`'/> -->
          <path v-for='i in 5' :d='path' :fill='colorFill(i)'
            :stroke='colorStroke(i)' :stroke-width='2 / 3'
            :transform='`translate(${(i - 0.5) * 25}, 0)scale(3)`'/>
        </g>
      </svg>
    </div>
    <div v-if='!isMobile' class='column'>
      <p v-if='inEnglish'>
        <strong>artist references</strong><br />
        <span v-if='featured'>{{ featured.data.references }} references</span>
      </p>
      <p v-if='!inEnglish'>
        <strong>有關藝術家的參考資料</strong><br />
        <span v-if='featured'>{{ featured.data.references }}項</span>
      </p>
      <svg>
        <g transform='translate(0, 30)'>
          <path :d='path' :fill='bumpFill(1)' :stroke-width='2 / 3'
            :stroke='featured ? featured.colors[1] : colorScale(0.5)'
            transform='translate(10, 0)scale(3)' />
          <path :d='path2' :fill='bumpFill(2)' :stroke-width='2 / 3'
            :stroke='featured ? featured.colors[1] : colorScale(0.5)'
            transform='translate(30, -18.5)scale(3)' />
          <path :d='path3' :fill='bumpFill(3)' :stroke-width='2 / 3'
            :stroke='featured ? featured.colors[1] : colorScale(0.5)'
            transform='translate(68, -18.5)scale(3)' />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as d3 from 'd3'

const colorStep = 1 / 5
export default {
  name: 'readme',
  props: ['featured', 'path', 'colorScale', 'isMobile', 'inEnglish'],
  data() {
    return {
      path2: `M7.81,6.12h-2h-3h-2l2.5-5l1,2l1-2L7.81,6.12z`,
      path3: `M9.81,6.12h-2h-2h-1h-2h-2l2.5-5l1,2l1-2l1,2l1-2L9.81,6.12z`,
    }
  },
  methods: {
    heightFill(i) {
      if (!this.featured) return this.colorScale(0.5)
      return i === Math.round(this.featured.height / 3) ? this.featured.colors[1] : '#fff'
    },
    colorFill(i) {
      if (!this.featured) return this.colorScale(i / 5)
      const t = Math.floor(this.featured.color / colorStep) + 1
      return i === t ? this.colorScale(t / 5) : '#fff'
    },
    colorStroke(i) {
      if (!this.featured) return this.colorScale(i / 5)
      return this.colorScale(i / 5)
    },
    bumpFill(i) {
      if (!this.featured) return this.colorScale(0.5)
      return i === (this.featured.bumps - 1) / 2 ? this.featured.colors[1] : '#fff'
    },
  }
}
</script>

<style>
.grid {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-gap: 40px;
}

.column p {
  white-space: nowrap;
  margin-bottom: 0;
}

svg {
  overflow: visible;
  width: 115px;
  height: 30px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-gap: 20px;
  }
}
</style>
