<template>
  <div id="infobox" :style='{opacity, display}'>
    <a class='header' :href='artistURL' target='_new'>{{ featured.data.name }}</a>
    <div class='bio' v-if='inEnglish'>
      Years active: {{ featured.data.info && featured.data.info.yearsActive || 'N/A' }}<br />
      Occupation: {{ featured.data.info &&
        (typeof(featured.data.info.occupation) === 'object' ? featured.data.info.occupation.join(', ') :
        featured.data.info.occupation) || 'N/A' }}
    </div>
    <div v-if='!inEnglish'>
      活躍年分：{{ featured.data.info && featured.data.info.yearsActive || '未有提供' }}<br />
      職業：{{ featured.data.info &&
        (typeof(featured.data.info.occupation) === 'object' ? featured.data.info.occupation.join(', ') :
        featured.data.info.occupation) || '未有提供' }}
    </div>
    <div class='content'>
      <Legend v-bind='{featured, path, colorScale, isMobile, inEnglish}'></Legend>
      <div>
        <p>{{ truncate(featured.data.summary) }}</p>
        <p v-if='inEnglish'>
          <a :href='artistURL' target='_new'>
            read more →
          </a>
        </p>
        <p v-if='!inEnglish'>
          <a :href='artistURL' target='_new'>
            查看更多 →
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as d3 from 'd3'
import Legend from './Legend.vue'

export default {
  name: 'infobox',
  components: {Legend},
  props: [
    'mountains', 'path', 'tl2', 'tl3',
    'outerRadius', 'colorScale', 'isMobile', 'inEnglish',
  ],
  data() {
    return {
      opacity: 1,
      display: 'none',
      featured: _.last(this.mountains),
    }
  },
  computed: {
    artistURL() {
      return `http://wikipedia.org/wiki/${this.featured.data.name.replace(" ", "_")}`
    },
  },
  mounted() {
    const duration = 0.05
    // fade in
    this.tl2.set(this.$data, {display: 'block'}, 0)
    this.tl2.fromTo(this.$data, duration, {opacity: 0}, {opacity: 1}, 0)

    const zPositionOffset = 10
    const zScale = d3.scaleLinear().domain([-this.outerRadius, 0])
    _.each(this.mountains, (d, i) => {
      this.tl2.set(this.$data, {featured: d}, zScale(d.z))
    })

    this.tl3.to(this.$data, 0.2, {opacity: 0}, 0)
    this.tl3.set(this.$data, {display: 'none'}, 0.2)
    // this.tl2.set(this.$data, {featured: {}}, 1)
  },
  methods: {
    truncate(summary) {
      const length = this.isMobile ? 180 : 420
      return _.truncate(summary, {length, separator: /,? +/})
    }
  }
}
</script>

<style scoped>
#infobox {
  position: absolute;
  top: 50vh;
  width: 650px;
  left: 50vw;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.header {
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  font-size: 1.25em;
}

.content {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  #infobox {
    width: calc(100% - 60px);
    padding: 40px 40px 20px 20px;
    top: 0px;
    left: 0px;
    transform: translate(0%, 0%);
  }

  .content {
    grid-template-columns: auto;
    grid-gap: 0;
  }
}

h3 {
  margin: 0;
}

.bio {
  font-style: italic;
}

a {
  pointer-events: auto;
}
</style>
