<template>
  <div id="timeline" :style='{opacity}'>
    <svg :width='width' :height='height'>
      <!-- triangles -->
      <g>
        <path v-for='(d, i) in triangles' class='triangle'
          :transform='`translate(${d.x}, ${d.y})scale(${(d.scale) / 3})`'
          :d='path' :fill='featured.data && featured.data.name === d.name ? highlight : d.color'
          :stroke='featured.data && featured.data.name === d.name ? highlight : d.color'
          :stroke-width='4 / d.scale' :opacity='d.opacity' fill-opacity='0.85' @click='clickArtist'
          @mouseenter='hoverArtist(`woman`, d, i)' @mouseleave='hoverArtist(null)' />
      </g>
      <!-- stars -->
      <g>
        <circle v-for='(d, i) in circles' class='circle'
          :cx='d.x' :cy='Math.ceil(d.y)'
          :r='d.height - 1' :fill='d.color' :stroke='d.color'
          :fill-opacity='d.opacity * 0.85' :stroke-opacity='d.opacity' @click='clickArtist'
          @mouseenter='hoverArtist(`man`, d, i)' @mouseleave='hoverArtist(null)' />
      </g>

      <!-- SCENE ONE -->
      <defs>
        <clipPath id='verticalClipPath'>
          <rect :x='sceneOne.left - 5' :y='sceneOne.clipPathY'
            width='10' :height='sceneOne.clipPathHeight' />
        </clipPath>
        <clipPath id='medianClipPath'>
          <rect :x='sceneOne.left' :y='sceneOne.top'
            :width='sceneOne.medianPathWidth' :height='sceneOne.height' />
        </clipPath>
        <clipPath id='maxClipPath'>
          <rect :x='sceneOne.left' :y='sceneOne.top'
            :width='sceneOne.maxPathWidth' :height='sceneOne.height' />
        </clipPath>
      </defs>
      <!-- arrow up -->
      <path :d='`M${sceneOne.left}, ${sceneOne.bottom}
        V${sceneOne.top} l-3,7 h6 l-3,-7`' clip-path='url(#verticalClipPath)'
        fill='#333' stroke='#333' />
      <!-- median -->
      <path :d='`M${sceneOne.left},${sceneOne.medianY} h${sceneOne.width}`'
        clip-path='url(#medianClipPath)' shape-rendering='crispEdges'
        stroke='#999' stroke-dasharray='5 2' />
      <!-- max -->
      <path :d='`M${sceneOne.left},${sceneOne.maxY} h${sceneOne.width}`'
        clip-path='url(#maxClipPath)' shape-rendering='crispEdges'
        stroke='#999' stroke-dasharray='5 2' />

      <!-- SCENE TWO -->
      <defs>
        <clipPath id='medianClipPathRight'>
          <rect :x='sceneTwo.left' :y='sceneOne.top'
            :width='sceneTwo.medianPathWidth' :height='sceneOne.height' />
        </clipPath>
        <clipPath id='maxClipPathRight'>
          <rect :x='sceneTwo.left' :y='sceneOne.top'
            :width='sceneTwo.maxPathWidth' :height='sceneOne.height' />
        </clipPath>
      </defs>
      <!-- median -->
      <path :d='`M${sceneTwo.left + 10},${sceneTwo.medianY} h${sceneOne.width}`'
        clip-path='url(#medianClipPathRight)' shape-rendering='crispEdges'
        stroke='#999' stroke-dasharray='5 2' />
      <!-- max -->
      <path :d='`M${sceneTwo.left + 10},${sceneTwo.maxY} h${sceneOne.width}`'
        clip-path='url(#maxClipPathRight)' shape-rendering='crispEdges'
        stroke='#999' stroke-dasharray='5 2' />
    </svg>

    <!-- SCENE ONE -->
    <div class='label' :style='{
      left: `${sceneOne.left}px`,
      width: `${sceneOne.width - 10}px`,
      top: `${sceneOne.bottom}px`,
      borderTop: `1px solid`,
      opacity: sceneOne.artistOpacity,
    }'>
      <strong>{{ mountains.length }}</strong><span v-if='!inEnglish'>位女性藝術家</span>
      <span v-if='inEnglish'>women artists</span>
    </div>
    <div class='label' :style='{
      width: `100px`,
      top: `${sceneOne.top - 10}px`,
      left: `${sceneOne.left}px`,
      transform: `translate(-50%, -100%)`,
      opacity: sceneOne.lengthOpacity,
    }'>
      <span v-if='inEnglish'>article length</span>
      <span v-if='!inEnglish'>文章篇幅</span>
    </div>
    <div class='label' :style='{
      top: `${sceneOne.medianY}px`,
      left: `${sceneOne.left + sceneOne.width}px`,
      transform: `translate(-100%, -50%)`,
      opacity: sceneOne.medianOpacity,
    }'>
      <span v-if='inEnglish'>median</span>
      <span v-if='!inEnglish'>中位數</span>
      <div style='font-size: 0.7em'>
        {{ Math.floor(sceneOne.medianLength) }}<span v-if='!inEnglish'>字</span>
        <span v-if='inEnglish'>words</span>
      </div>
    </div>
    <div class='label' :style='{
      top: `${sceneOne.maxY}px`,
      left: `${sceneOne.left + sceneOne.width}px`,
      transform: `translate(-100%, -50%)`,
      opacity: sceneOne.maxOpacity,
    }'>
      <span v-if='inEnglish'>max</span>
      <span v-if='!inEnglish'>最多</span>
      <div style='font-size: 0.7em'>
        {{ sceneOne.maxLength }}<span v-if='!inEnglish'>字</span>
        <span v-if='inEnglish'>words</span>
      </div>
    </div>

    <!-- SCENE TWO -->
    <div class='label' :style='{
      left: `${sceneTwo.left + 10}px`,
      width: `${sceneOne.width - 10}px`,
      top: `${sceneOne.bottom}px`,
      borderTop: `1px solid`,
      opacity: sceneTwo.artistOpacity,
    }'>
      <strong>{{ stars.length }}</strong><span v-if='!inEnglish'>位男性藝術家</span>
      <span v-if='inEnglish'>male artists</span>
    </div>
    <div class='label' :style='{
      top: `${sceneTwo.medianY}px`,
      left: `${sceneTwo.left + sceneOne.width}px`,
      transform: `translate(-100%, -50%)`,
      opacity: sceneTwo.medianOpacity,
    }'>
      <span v-if='inEnglish'>median</span>
      <span v-if='!inEnglish'>中位數</span>
      <div style='font-size: 0.7em'>
        {{ Math.floor(sceneTwo.medianLength) }}<span v-if='!inEnglish'>字</span>
        <span v-if='inEnglish'>words</span>
      </div>
    </div>
    <div class='label' :style='{
      top: `${sceneTwo.maxY}px`,
      left: `${sceneTwo.left + sceneOne.width}px`,
      transform: `translate(-100%, -50%)`,
      opacity: sceneTwo.maxOpacity,
    }'>

      <span v-if='inEnglish'>max</span>
      <span v-if='!inEnglish'>最多</span>
      <div style='font-size: 0.7em'>
        {{ sceneTwo.maxLength }}<span v-if='!inEnglish'>字</span>
        <span v-if='inEnglish'>words</span>
      </div>
    </div>

    <!-- WORDS -->
    <div class='content' :style='{
      top: `50vh`,
      left: `${sceneOne.contentLeft + 10}px`,
      width: `${sceneOne.width - 10}px`,
      transform: `translate(0, -50%)`,
    }'>
      <div v-if='inEnglish'>
        <p :style='{opacity: sceneOne.contentOpacity}'>
          Wikipedia is user-contributed and therefore may read as a good reflection of the internet’s collective knowledge. But it is a very Western-centric view, and Asian women artists are extremely under-represented. There are currently only <strong>fifty-eight</strong> women categorised as Hong Kong women artists on Wikipedia, and their articles have an average length of six hundred words—half that of an average Wikipedia article.
        </p>
        <p :style='{opacity: sceneTwo.contentOpacity}'>
          On the other hand, there are <strong>222</strong> Wikipedia pages for male Hong Kong artists—four times the number of women, with the longest article at more than double the average word count.
        </p>
        <p :style='{opacity: sceneThree.contentOpacity}'>
          Even within the existing articles, almost half of the women are missing basic biographical information (denoted in <span :style='{color: red, fontWeight: `bold`}'>red</span>), such as date and place of birth, years active, and occupation.
        </p>
        <p :style='{opacity: sceneOne.contentOpacity}'>
          <sup>* According to <a href="https://wikicount.net/" target="_new">wikicount.net</a>, a third-party aggregator.</sup>
        </p>
      </div>
      <div v-if='!inEnglish'>
        <p :style='{opacity: sceneOne.contentOpacity}'>
          維基百科的內容由用戶撰寫，因此可視為互聯網上集體知識之反映。然而，這樣的內容是以西方觀點為中心，當中亞洲女性藝術家極受忽略。目前，僅有<strong>58</strong>個條目在維基百科上歸類為香港女性藝術家，每個條目的平均字數為六百，是一般維基百科條目的一半*。
        </p>
        <p :style='{opacity: sceneTwo.contentOpacity}'>
          另一方面，關於香港男性藝術家的維基百科條目則有<strong>222</strong>條，為女性藝術家之四倍；其中最長的條目，字數是平均篇幅的兩倍以上。
        </p>
        <p :style='{opacity: sceneThree.contentOpacity}'>
          此外，在現有關於香港女性藝術家的條目中，近半數並無該藝術家的基本生平資料（以<span :style='{color: red, fontWeight: `bold`}'>紅色</span>標示），如出生日期及地點、活躍年份和職業等。
        </p>
        <p :style='{opacity: sceneOne.contentOpacity}'>
          <sup>* 數據由第三方機構<a href="https://wikicount.net/" target="_new">wikicount.net</a>整合。</sup>
        </p>
      </div>
    </div>
    <!-- SCENE FOUR -->
    <div class='content' :style='{
      top: `50vh`,
      left: `${sceneOne.contentLeft + 10}px`,
      width: `${sceneOne.width - 10}px`,
      transform: `translate(0, -50%)`,
      opacity: sceneFour.contentOpacity,
    }'>
      <div v-if='inEnglish'>
        <p>
          Help us <strong>expand articles</strong> that are missing basic biographical information (denoted in <span :style='{color: red, fontWeight: `bold`}'>red</span>). Help us <strong>surface more artists</strong> through this project by creating and categorising articles as <a href='https://en.wikipedia.org/wiki/Category:Hong_Kong_women_artists' target='_new'>‘Hong Kong women artists’</a>.
        </p>
      </div>
      <div v-if='!inEnglish'>
        <p>
          歡迎協助我們為欠缺基本生平資料的條目（以<span :style='{color: red, fontWeight: `bold`}'>紅色</span>標示）補充內容，以及撰寫有關香港女性藝術家的條目，並將條目歸類，令更多這些藝術家能為世人認識。
        </p>
      </div>
    </div>

    <!-- FINAL SCENE -->
    <div class='content' :style='{
      top: `50vh`,
      left: `50vw`,
      width: `600px`,
      transform: `translate(-50%, -50%)`,
      opacity: sceneFive.contentOpacity,
    }'>
      <div v-if='inEnglish'>
        <span class='underline'>METHODOLOGY</span>
        <p>A list of women artists was gathered by scraping (programmatically reading through) the <a href="https://en.wikipedia.org/wiki/Category:Hong_Kong_women_artists" target="_new">‘Hong Kong women artists’</a> page. A list of male artists was gathered by scraping every article and every subcategory page under the wider <a href="https://en.wikipedia.org/wiki/Category:Hong_Kong_artists" target="_new">‘Hong Kong artists’</a> category and subtracting the women artists from that list. Artist details were then gathered via the MediaWiki API, which returned data such as summaries, backlinks, and references. Word count was calculated by looping through every article section and adding up the number of words.</p>
        <p>As a user-contributed website, Wikipedia is prone to mistakes. Pages can be categorised incorrectly, such as when a male artist was accidentally tagged as female. There are also ambiguities in how a user might define ‘artist’ (does this include actresses, for example?), how they might qualify ‘Hong Kong based’, and, most importantly, how they might account for gender, which can be self-defined or non-binary. Finally, because the pages were only scraped in English, the data set is inherently skewed towards a Western perspective.</p>
        <p>As the work continues to live online, we hope it will inspire contributions on Wikipedia. The data set will be updated every six months with articles added to the ‘Hong Kong women artists’ category.</p>
        <p>
          <em>Last updated {{ lastUpdated }}</em>
        </p>
      </div>
      <div v-if='!inEnglish'>
        <span class='underline'>資料收集方法</span>
        <p>女性藝術家名單從「<a href="https://en.wikipedia.org/wiki/Category:Hong_Kong_women_artists" target="_new">香港女性藝術家</a>」頁面「抓取」（以程式擷取網頁內容）；男性藝術家名單則從涵蓋範圍更廣的「<a href="https://en.wikipedia.org/wiki/Category:Hong_Kong_artists" target="_new">香港藝術家</a>」類別的文章和子分類頁面「抓取」，再略去女性藝術家的條目。藝術家資料利用MediaWiki應用程式介面（API）收集，API會傳回概要、反向鏈結和參考資料等數據。條目字數則逐個部分計算，從而得出其總字數。</p>
        <p>維基百科的條目由用戶創建，內容難免有錯誤——頁面可能被錯誤分類，例如男性藝術家被歸類為女性藝術家；用戶對何謂藝術家（例如應否視藝人為藝術家）、何謂「駐居於香港」等可能有歧義，而最重要的是如何處理性別？它是可以自行定義、不一定是非此即彼的。最後，由於「抓取」的頁面僅為英文，數據集很自然地偏向從西方視角出發。</p>
        <p>此作品將繼續於互聯網上供人瀏覽，我們希望藉此啟發更多人到維基百科貢獻更多內容。數據集將每六個月更新一次，增添獲分類為「香港女性藝術家」的條目。</p>
        <p style="color:#737373">
          最後更新日期為{{ lastUpdated }}
        </p>
      </div>
    </div>

    <div class='content' :style='{
      bottom: `20px`,
      left: `50vw`,
      width: `50%`,
      transform: `translate(-50%, 0)`,
      opacity: sceneFive.contentOpacity,
      textAlign: `center`,
      fontSize: `0.75em`,
    }'>
      <div v-if='inEnglish'>
        Created with 💖 by <a href='http://sxywu.com/' target='_new'>Shirley Wu</a> with technical support from <a href='https://mattdesl.com/' target='_new'>Matt DesLauriers</a> | Commissioned by <a href='https://stories.mplus.org.hk/en/interactives/' target='_new'>M+</a>, 2020
      </div>
      <div v-if='!inEnglish'>
        由 <a href='http://sxywu.com/' target='_new'>Shirley Wu</a> 用💖創作，<a href='https://mattdesl.com/' target='_new'>Matt DesLauriers</a>提供技術支援 | <a href='https://stories.mplus.org.hk/en/interactives/' target='_new'>M+</a>委約作品，2020年
      </div>
    </div>

    <!-- HOVER -->
    <div v-if='hovered' class='hovered' :style='{
      top: `${hovered.y + 5}px`,
      left: `${hovered.x + 5}px`,
    }'>
      <strong>{{ hovered.name }}</strong> ({{ hovered.content }}{{ inEnglish ? ' words' : '字' }})<br />
      <sup v-if='inEnglish'><em>Click to open Wikipedia page</em></sup>
      <sup v-if='!inEnglish'>點擊開啟維基百科頁面</sup>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as d3 from 'd3'
import lastUpdatedFile from '../assets/date.txt'

const width = 360
const margin = {top: 100, right: 0, bottom: 80, left: 0}
const red = '#ec4977'

export default {
  name: 'timeline',
  props: ['inEnglish', 'mountains', 'stars', 'outerRadius', 'path', 'tl2', 'tl3'],
  data() {
    return {
      red,
      highlight: '#000',
      width: window.innerWidth,
      height: window.innerHeight,
      opacity: 0,
      triangles: [],
      circles: [],
      featured: {},
      sceneOne: {},
      sceneTwo: {},
      sceneThree: {},
      sceneFour: {},
      sceneFive: {},
      lastUpdated: '',
      hovered: null,
      dateObj: null,
    }
  },
  created() {
    // load date text ._____.
    fetch(lastUpdatedFile).then(resp => resp.text())
      .then(date => {
        this.dateObj = new Date(date)
        this.calculateLastUpdated()
      })
  },
  mounted() {
    this.yScale = d3.scaleLinear().range([this.height - margin.bottom, margin.top])
    this.simulation = d3.forceSimulation()
      .force('x', d3.forceX().x(d => d.focusX))
      .force('collide', d3.forceCollide().radius(d => d.height))

    // fade in
    this.tl2.to(this.$data, 0.05, {opacity: 1}, 0)

    this.setupSceneZero()
    this.setupSceneOne()
    this.setupSceneTwo()
    this.setupSceneThree()
    this.setupSceneFour()
    this.setupSceneFive()
  },
  watch: {
    inEnglish() {
      this.calculateLastUpdated()
    },
  },
  methods: {
    setupSceneZero() {
      const xScale = d3.scaleLinear()
        .domain(d3.extent(this.mountains, d => d.x))
        .range([this.width - 50, this.width - 30])
      const zPositionOffset = 10
      const zScale = d3.scaleLinear().domain([-this.outerRadius, 0])
      const top = 30
      const bottom = 60

      this.triangles = _.map(this.mountains, d => {
        return {
          x: xScale(d.x),
          y: zScale(d.z) * (this.height - top - bottom) + top,
          scale: d.height,
          color: d.colors[1],
          name: d.data.name,
          opacity: 1,
        }
      })

      _.each(this.mountains, (d, i) => {
        this.tl2.set(this.$data, {featured: d}, zScale(d.z))
      })
      // set featured back to null
      this.tl2.set(this.$data, {featured: {}}, 1)
    },
    setupSceneOne() {
      const left = this.width / 2 - width
      const height = this.height - margin.left - margin.right
      const maxLength = d3.max(this.mountains, d => d.data.content)
      const medianLength = d3.median(this.mountains, d => d.data.content)
      this.yScale.domain([0, maxLength]).nice()

      // translate triangles to first position
      const positions = this.sceneOnePositions = _.map(this.mountains, d => {
        return {
          focusX: left + width / 2,
          fy: this.yScale(d.data.content),
          x: left + width / 2,
          y: this.yScale(d.data.content),
          height: 5,
        }
      })

      // triangle positions
      this.simulation.nodes(positions)
      _.times(100, i => this.simulation.tick())

      // boundaries
      this.sceneOne = {
        top: margin.top,
        bottom: this.height - margin.bottom,
        left,
        width, height,
        artistOpacity: 0,
        clipPathY: this.height - margin.bottom,
        clipPathHeight: 0,
        lengthOpacity: 0,
        medianLength, maxLength,
        medianY: this.yScale(medianLength),
        maxY: d3.min(positions, d => d.fy),
        medianPathWidth: 0,
        medianOpacity: 0,
        maxPathWidth: 0,
        maxOpacity: 0,
        contentLeft: this.width / 2,
        contentOpacity: 0,
      }

      const duration = 0.1
      // timeline animations
      this.tl3.set(this.$data, {opacity: 1}, 0)
      this.tl3.staggerTo(this.triangles, duration, {
        cycle: {
          x: (i) => positions[i].x,
          y: (i) => positions[i].y,
        },
        scale: 4,
      }, 0.01, 0)

      // fade in "women artists"
      this.tl3.to(this.sceneOne, duration,
        {artistOpacity: 1}, this.triangles.length / 400)
      // animate line up
      this.tl3.to(this.sceneOne, 2 * duration,
        {clipPathY: margin.top, clipPathHeight: height}, `-=${2 * duration}`)
      // animate content in
      this.tl3.to(this.sceneOne, duration, {contentOpacity: 1}, `-=${duration}`)
      // animate length label in
      this.tl3.to(this.sceneOne, duration, {lengthOpacity: 1})
      // animate median in
      this.tl3.to(this.sceneOne, duration, {medianPathWidth: width})
      this.tl3.to(this.sceneOne, duration, {medianOpacity: 1})
      // animate max in
      this.tl3.to(this.sceneOne, duration, {maxPathWidth: width})
      this.tl3.to(this.sceneOne, duration, {maxOpacity: 1})
    },
    setupSceneTwo() {
      const left = this.width / 2 - 0.5 * width
      const maxLength = d3.max(this.stars, d => d.data.content)
      const medianLength = d3.median(this.stars, d => d.data.content)
      this.yScale.domain([0, maxLength]).nice()

      // STEP 1: MOUNTAINS & MOVE EVERYTHING
      const positions = _.map(this.mountains, (d, i) => {
        return {
          x: this.sceneOnePositions[i].x - 0.5 * width,
          y: this.yScale(d.data.content),
        }
      })
      const medianY = this.yScale(this.sceneOne.medianLength)
      const maxY = this.yScale(this.sceneOne.maxLength)

      // STEP 2: STARS
      this.circles = _.map(this.stars, d => {
        return {
          focusX: left + width / 2,
          fy: this.yScale(d.data.content),
          x: left + width / 2,
          y: this.yScale(d.data.content),
          color: d.color,
          height: 4,
          opacity: 0,
        }
      })
      this.simulation
        // .force('y', d3.forceY().y(d => d.focusY))
        .nodes(this.circles)
      _.times(300, i => {
        this.simulation.tick()
        _.each(this.circles, d => {
          if (d.y >= this.sceneOne.bottom - d.height) {
            d.y = this.sceneOne.bottom - d.height
          }
        })
      })

      // const timesCount = this.stars.length / this.mountains.length
      this.sceneTwo = {
        artistOpacity: 0,
        left,
        medianY: this.yScale(medianLength),
        maxY: d3.min(this.circles, d => d.y),
        maxLength, medianLength,
        medianPathWidth: 0,
        maxPathWidth: 0,
        medianOpacity: 0,
        maxOpacity: 0,
        contentOpacity: 0,
        // timesCount: Math.round(timesCount),
        // timesCountQualifier: Math.round(timesCount) === Math.floor(timesCount)
        //   ? 'more than' : 'almost',
      }

      // ANIMATIONS
      // animate mountains down
      const duration = 0.1
      this.tl3.add('two')
      this.tl3.staggerTo(this.triangles, duration, {
        cycle: {
          y: (i) => positions[i].y,
        }
      }, 0, 'two')
      // animate mountains down and fade in content
      this.tl3.to(this.sceneOne, duration, {
        medianY, maxY,
      }, 'two')
      this.tl3.to(this.sceneTwo, duration, {contentOpacity: 1}, `-=${duration / 2}`)
      // push everything apart
      this.tl3.add('two-move', `-=${duration / 2}`)
      this.tl3.staggerTo(this.triangles, duration, {
        cycle: {
          x: (i) => positions[i].x,
        }
      }, 0, 'two-move')
      this.tl3.to(this.sceneOne, duration, {
        left: this.width / 2 - 1.5 * width,
        contentLeft: this.width / 2 + 0.5 * width,
      }, `two-move`)

      this.tl3.add('two-circles', `-=${duration / 2}`)
      // fade circles in
      this.tl3.to(this.sceneTwo, duration, {artistOpacity: 1}, 'two-circles')
      this.tl3.staggerTo(this.circles, duration, {opacity: 1}, 0.001, 'two-circles')
      // animate median
      this.tl3.add('two-median', `-=${2 * duration}`)
      this.tl3.to(this.sceneTwo, duration,
        {medianPathX: this.width / 2, medianPathWidth: width}, 'two-median')
      this.tl3.to(this.sceneTwo, duration, {medianOpacity: 1}, `two-median+=${duration / 2}`)
      // animate max
      this.tl3.add('two-max',  `-=${duration}`)
      this.tl3.to(this.sceneTwo, duration,
        {maxPathX: this.width / 2, maxPathWidth: width}, 'two-max')
      this.tl3.to(this.sceneTwo, duration, {maxOpacity: 1}, `two-max+=${duration / 2}`)
    },
    setupSceneThree() {
      this.sceneThree = {
        contentOpacity: 0,
      }

      const duration = 0.1
      // no info triangles
      this.tl3.add('three')

      const noInfoTriangles = _.filter(this.triangles, (d, i) => this.mountains[i].data.noInfo)
      this.tl3.staggerTo(noInfoTriangles, duration, {color: red}, 0.005, 'three')

      const noInfoCircles = _.filter(this.circles, (d, i) => this.stars[i].data.noInfo)
      this.tl3.staggerTo(noInfoCircles, duration, {color: red}, 0.005, 'three')

      // console.log(noInfoTriangles.length, noInfoCircles.length)

      this.tl3.to(this.sceneThree, duration, {contentOpacity: 1}, `three+=${duration / 2}`)
    },
    setupSceneFour() {
      const maxLength = d3.max(this.mountains, d => d.data.content)
      this.yScale.domain([0, maxLength]).nice()
      const positions = _.map(this.mountains, d => {
        return {
          y: this.yScale(d.data.content),
        }
      })

      this.sceneFour = {
        contentOpacity: 0,
      }
      const duration = 0.2

      this.tl3.add('four')
      // fade everything and also bring the triangles back to center
      this.tl3.to(this.sceneOne, duration, {
        left: this.width / 2 - width,
        artistOpacity: 0,
        lengthOpacity: 0,
        clipPathY: this.height - margin.bottom,
        clipPathHeight: 0,
        medianPathWidth: 0,
        medianOpacity: 0,
        maxPathWidth: 0,
        maxOpacity: 0,
        contentLeft: this.width / 2,
        contentOpacity: 0,
      }, 'four')
      this.tl3.staggerTo(this.triangles, duration, {
        cycle: {
          x: (i) => this.sceneOnePositions[i].x,
          y: (i) => positions[i].y,
        }
      }, 0, 'four')

      this.tl3.to(this.sceneTwo, duration, {
        artistOpacity: 0,
        medianPathWidth: 0,
        maxPathWidth: 0,
        medianOpacity: 0,
        maxOpacity: 0,
        contentOpacity: 0,
      }, 'four')
      this.tl3.staggerTo(this.circles, duration, {opacity: 0}, 0, 'four')

      this.tl3.to(this.sceneThree, duration, {contentOpacity: 0}, 'four')

      this.tl3.to(this.sceneFour, duration, {contentOpacity: 1}, `four+=${duration / 2}`)
    },
    setupSceneFive() {
      const duration = 0.2
      this.tl3.add('five')

      this.tl3.to(this.sceneFour, duration, {contentOpacity: 0}, 'five')
      this.tl3.staggerTo(this.triangles, duration, {opacity: 0}, 0, 'five')

      this.sceneFive = {
        contentOpacity: 0,
      }
      this.tl3.to(this.sceneFive, duration, {contentOpacity: 1}, `five+=${duration / 2}`)
    },
    hoverArtist(gender, data, index) {
      if (!gender || this.tl3.progress() === 0 ||
        data.opacity === 0 || data.x > this.width - 100) {
        this.hovered = null
        return
      }

      let artist = this.mountains[index]
      if (gender === 'man') {
        artist = this.stars[index]
      }

      this.hovered = {
        name: artist.data.name,
        content: artist.data.content,
        x: data.x, y: data.y,
      }
    },
    clickArtist() {
      if (!this.hovered) return

      window.open(`http://wikipedia.org/wiki/${this.hovered.name.replace(" ", "_")}`, '_new')
    },
    calculateLastUpdated() {
      if (this.inEnglish) {
        this.lastUpdated = d3.timeFormat('%d %B, %Y')(this.dateObj)
      } else {
        this.lastUpdated = d3.timeFormat('%Y年%m月%d日')(this.dateObj)
      }
    },
  }
}
</script>

<style scoped>
#timeline {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: visible;
  pointer-events: none;
}

.triangle, .circle, a {
  pointer-events: auto;
}

.label {
  position: absolute;
  text-align: center;
  padding: 3px;
}

.content {
  position: absolute;
  padding: 5px;
}

.hovered {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 5px #cfcfcf;
  font-size: 0.85em;
  padding: 2px 7px;
}

.underline {
  border-bottom: 1px solid;
}
</style>
