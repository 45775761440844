<template>
  <div id="app">
    <World v-bind='{
      isMobile, inEnglish,
      mountains, stars, outerRadius, width, height,
      tl1: timelines[0], tl2: timelines[1], tl3: timelines[2],
    }'></World>
    <Timeline v-if='!isMobile' v-bind='{
      mountains, stars, outerRadius, path: trianglePath,
      inEnglish, tl2: timelines[1], tl3: timelines[2],
    }'></Timeline>
    <TimelineMobile v-if='isMobile' v-bind='{
      mountains, stars, outerRadius, path: trianglePath,
      inEnglish, tl2: timelines[1], tl3: timelines[2],
    }'></TimelineMobile>
    <Infobox v-bind='{
      isMobile, inEnglish,
      mountains, outerRadius, path: trianglePath,
      tl2: timelines[1], tl3: timelines[2], colorScale: topColorScale,
    }'></Infobox>
    <Intro v-bind='{
      isMobile, inEnglish,
      tl: timelines[0], path: trianglePath, colorScale: topColorScale,
    }'></Intro>
    <div class='scroll' :style='{opacity: arrowOpacity}'>
      ↓
    </div>
    <div class='language' @click='inEnglish = !inEnglish'>
      <span :class='inEnglish ? "underline" : ""'>EN</span>
      |
      <span :class='!inEnglish ? "underline" : ""'>中</span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as d3 from 'd3'
import chroma from 'chroma-js'
import {TimelineLite} from 'gsap'
import p5 from 'p5'
import isMobile from 'ismobilejs'

import Intro from './components/Intro.vue'
import World from './components/World.vue'
import Infobox from './components/Infobox.vue'
import Timeline from './components/Timeline.vue'
import TimelineMobile from './components/TimelineMobile.vue'
import data from './assets/women_artists.json'
import menData from './assets/male_artists.json'
const maxWidth = 60
const maxHeight = 15
const docHeight = 30000
const topColorScale = chroma.scale(["#d2f8f7", "#6ad1c8", "#007bc1"]).mode('lch')
const bottomColorScale = chroma.scale(["#ffdde9", "#eec2de"]).mode('lch')
const starColorScale = chroma.scale(["#fff9e0", "#fcf594", "#fcd6d9"]).mode('lch')

p5.prototype.noiseSeed(8)

export default {
  name: 'app',
  components: {Intro, World, Infobox, Timeline, TimelineMobile},
  data() {
    const sectionHeights = [docHeight * 0.15, docHeight * 0.55, docHeight * 0.3]
    return {
      isMobile: isMobile.phone,
      inEnglish: true,
      trianglePath: 'M-2.5,0l2.5-5l2.5,5H-2.5z',
      mountains: [],
      stars: [],
      outerRadius: 20,
      width: window.innerWidth,
      height: window.innerHeight,
      topColorScale,
      // section positions
      sections: [
        {index: 0, height: sectionHeights[0], top: 0, bottom: sectionHeights[0]},
        {
          index: 1,
          height: sectionHeights[1],
          top: sectionHeights[0],
          bottom: sectionHeights[0] + sectionHeights[1],
        },
        {
          index: 2,
          height: sectionHeights[2],
          top: sectionHeights[0] + sectionHeights[1],
          bottom: sectionHeights[0] + sectionHeights[1] + sectionHeights[2],
        },
      ],
      // timelines
      timelines: [
        new TimelineLite({paused: true}),
        new TimelineLite({paused: true}),
        new TimelineLite({paused: true}),
      ],
      arrowOpacity: 1,
    }
  },
  created() {
    this.calculateMountains()
    this.calculateStars()
  },
  mounted() {
    // after everything has been added to the timelines
    // add one last thing for arrow to fade out
    this.timelines[2].to(this.$data, 0.1, {arrowOpacity: 0}, 'four')

    // handle window resize
    // window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    calculateMountains: function() {
      // scales
      const colorScale = d3.scaleLog().domain(d3.extent(data, d => d.backlinks))
      const widthScale = d3.scaleLog().domain(d3.extent(data, d => d.backlinks)).range([maxWidth / 2, maxWidth])
      const heightScale = d3.scaleLinear().domain(d3.extent(data, d => d.content)).range([maxHeight / 5, maxHeight])
      const bumpScale = d3.scaleQuantize().domain(d3.extent(data, d => d.references)).range([3, 5, 7])

      let z = 0
      this.mountains = _.chain(data)
        .sortBy(d => d.content)
        .map((d, i) => {
          const width = widthScale(d.backlinks)
          const height = heightScale(d.content)
          const color = colorScale(d.backlinks)
          const noise = p5.prototype.noise(i)
          z -= height / 2

          const mountain = {
            x: 1.5 * width * (noise - 0.5),
            z,
            width, height,
            bumps: bumpScale(d.references),
            color,
            colors: [
              // d3.interpolateRdPu(color + 0.15),
              // d3.interpolatePuBu(color + 0.15),
              bottomColorScale(color).hex(),
              topColorScale(color).hex(),
              '#fff9e0',
            ],
            data: d,
          }

          z -= height / 2 + 2
          return mountain
        })
        .value()

      this.outerRadius = Math.abs(z)
    },
    calculateStars: function() {
      const colorScale = d3.scaleLog().domain(d3.extent(menData, d => d.backlinks))
      const sizeScale = d3.scaleLinear()
        .domain(d3.extent(menData, d => d.content)).range([1, 3])
      this.stars = _.chain(menData)
        .sortBy(d => d.content)
        .map(d => {
          const color = colorScale(d.backlinks)
          const size = sizeScale(d.content)
          return {
            x: _.random(-maxWidth / 2, maxWidth / 2),
            y: _.random(maxHeight / 2, 1.5 * maxHeight),
            z: _.random(0, -this.outerRadius - 80),
            size: sizeScale(d.content),
            color: starColorScale(color).hex(),
            data: d,
          }
        }).value()
    },
    handleScroll: function() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0
      if (scrollTop > docHeight) return

      const {index, top, height} = _.find(this.sections, ({bottom}) => scrollTop <= bottom)
      const progress = (scrollTop - top) / height

      if (index > 0) {
        this.timelines[0].progress(1)
      }
      if (index > 1) {
        this.timelines[1].progress(1)
      }
      if (index < 2) {
        this.timelines[2].progress(0)
      }
      if (index < 1) {
        this.timelines[1].progress(0)
      }

      const tl = this.timelines[index].progress(progress)
    },
    handleWindowResize: function() {
      this.width = window.innerWidth
      this.height = window.innerHeight
    },
  },
}
</script>

<style scoped>
#app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scroll {
  animation: bounce 1s ease-in-out infinite;
  position: absolute;
  margin: 0;
  bottom: 10px;
  right: 20px;
  font-size: 1.5em;
  font-family: sans-serif;
}

.language {
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: sans-serif;
  cursor: pointer;
}

.underline {
  border-bottom: 1px solid;
  font-weight: bold;
}

@keyframes bounce {
  0%  { transform: translateY(0)   }
  50% { transform: translateY(-10px)}
  100% { transform: translateY(0)   }
}

@media (min-width: 320px) and (max-width: 480px) {
  .scroll {
    bottom: 2px;
    right: 10px;
  }

  .language {
    top: auto;
    left: 10px;
    bottom: 10px;
  }
}

</style>
